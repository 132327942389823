import React from "react";
import {
  SEO,
  PageLayout,
  Section,
  MarkdownContent,
  PaginatedGrid,
  multipleComponentIterator,
  BlogPostLink,
  SlideReveal,
  FullImageCard,
  FormattedDate,
  FishermanIcon,
} from "@bluefin/components";
import { Header, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class BlogPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessBlogPost,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          title={fishermanBusinessWebsitePage.seoTitle}
        />
        <PageLayout className={"blog-page"} hero={false}>
          <Section className={"blog-section"} textAlign={"center"}>
            <div className={"blog-page-header"}>
              <Header
                as={"h1"}
                textAlign={"center"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "blog_header",
                  defaultValue: "Blog",
                })}
              />
              <MarkdownContent
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "blog_description",
                })}
              />
            </div>
            <PaginatedGrid
              columns={2}
              rows={3}
              className={"blog-paginated-grid"}
              mobile={{ columns: 1, rows: 4, stackable: false }}
              tablet={{ columns: 2, rows: 3, stackable: false }}
              pagination={{
                directionIndicatorIcons: {
                  prevItem: "fancy-arrow-left",
                  nextItem: "fancy-arrow-right",
                },
              }}
              items={multipleComponentIterator({
                iterator: allFishermanBusinessBlogPost.nodes,
                components: [
                  {
                    component: <Card.Group itemsPerRow={1} />,
                    children: [
                      {
                        component: <BlogPostLink />,
                        propMap: { key: "_id", slug: "slug", title: "title" },
                        children: [
                          {
                            component: (
                              <SlideReveal
                                className={"ui card"}
                                triggerOnce={true}
                                direction={"up"}
                                fade={true}
                              />
                            ),
                            children: [
                              {
                                component: <FullImageCard />,
                                propMap: {
                                  key: "_id",
                                  image: "gatsbyHeroImage",
                                },
                                children: [
                                  {
                                    component: (
                                      <Card.Header className={"title"} />
                                    ),
                                    propMap: { content: "title" },
                                  },
                                  {
                                    component: (
                                      <FormattedDate outputFormat={"PPP"} />
                                    ),
                                    propMap: { date: "publishedAt" },
                                  },
                                  {
                                    component: (
                                      <Card.Description className={"summary"} />
                                    ),
                                    propMap: { content: "summary" },
                                  },
                                  {
                                    component: (
                                      <FishermanIcon
                                        iconName={"fancy-arrow-right"}
                                      />
                                    ),
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              })}
            />
          </Section>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Blog" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
        contentNodes {
          content
        }
      }
      seoTitle
    }
    allFishermanBusinessBlogPost(filter: { destination: { eq: "BLOG" } }) {
      nodes {
        _id
        title
        slug
        summary
        heroImage
        gatsbyHeroImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        publishedAt
      }
    }
  }
`;
